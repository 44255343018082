<template>
  <v-container fluid ref="top">
    <v-row class="mt-4" align="start">
      <v-col cols="12" md="6">
        <p class="title">
          {{ movementType && movementType === "insert" ? "Inclusão de Grupo Familiar:" : "Implantação:" }}
          Adicionar Titular
        </p>
      </v-col>
      <v-col cols="12" lg="6">
        <v-stepper tile value="2">
          <v-stepper-header>
            <v-stepper-step complete step="1"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" color="textPrimary">
              Informações Complementares
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4"></v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-container fill-height fluid class="mt-0 pt-0">
      <SimplifiedPrincipalInformations :beneficiaryContractedPlanProps="beneficiaryContractedPlan" />
    </v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-form ref="formRegister" v-model="isValidForm" lazy-validation>
          <v-card class="px-6 py-6" elevation="1">
            <v-row>
              <v-col cols="12">
                <p class="title">Informações Complementares do Beneficiário</p>
              </v-col>
              <v-col cols="12" md="3">
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    label="Data de Nascimento"
                    v-model="beneficiary.birthday"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    v-mask="'##/##/####'"
                    color="textPrimary"
                    :rules="beneficiary.birthday ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                    validate-on-blur
                  />
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  :items="maritalStatus"
                  v-model="beneficiary.maritalStatus"
                  label="Estado Civil"
                  placeholder="Escolha o estado civil"
                  item-text="description"
                  item-value="id"
                  outlined
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  :items="genders"
                  v-model="beneficiary.gender"
                  label="Gênero"
                  placeholder="Escolha o gênero"
                  item-text="description"
                  item-value="id"
                  outlined
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Nome da Mãe"
                  placeholder="Informe o nome da mãe"
                  v-model.trim="beneficiary.motherName"
                  @blur="beneficiary.motherName = formatter.formatToTitleCase(beneficiary.motherName)"
                  maxlength="70"
                  outlined
                  color="textPrimary"
                  :rules="beneficiary.motherName ? [rule.name, rule.validateIsAlphanumeric] : []"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="CNS"
                  placeholder="Informe o CNS"
                  v-model="beneficiary.cns"
                  v-mask="'###############'"
                  outlined
                  color="textPrimary"
                  :rules="beneficiary.cns ? [rule.cns] : []"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Declaração de Nascido Vivo (DNV)"
                  placeholder="Informe o DNV"
                  v-mask="'##-########-#'"
                  v-model="beneficiary.dnv"
                  outlined
                  :rules="beneficiary.dnv ? [rule.dnv] : []"
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Nome da empresa"
                  v-model.trim="beneficiary.companyName"
                  @blur="beneficiary.companyName = formatter.removeAccents(beneficiary.companyName)"
                  placeholder="Informe o nome"
                  color="textPrimary"
                  outlined
                  :rules="beneficiary.companyName ? [rule.validateCompanyName, rule.validateMaxTextSize(beneficiary.companyName, 255)] : []"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="CNPJ da empresa"
                  v-model="beneficiary.companyDocumentNumber"
                  v-mask="'##.###.###/####-##'"
                  placeholder="CNPJ da empresa"
                  color="textPrimary"
                  outlined
                  :rules="beneficiary.companyDocumentNumber ? [rule.cnpj] : []"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  :items="employmentRelationships"
                  v-model="beneficiary.employmentRelationship"
                  label="Vínculo Empregaticio"
                  placeholder="Escolha o vínculo"
                  item-text="description"
                  item-value="id"
                  outlined
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Carteira de Trabalho"
                  placeholder="Informe a carteira de trabalho"
                  :rules="[rule.validateWorkCard]"
                  v-mask="'#######'"
                  v-model="beneficiary.workCard"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="PIS / PASEP"
                  placeholder="Informe o PIS / PASEP"
                  v-mask="'###.#####.##-#'"
                  v-model="beneficiary.pisPasep"
                  :rules="beneficiary.pisPasep ? [rule.validatePis] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Matrícula"
                  placeholder="Informe a matrícula"
                  v-model="beneficiary.registration"
                  maxlength="50"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Complemento de Matrícula"
                  placeholder="Informe o complemento"
                  v-model="beneficiary.registrationComplement"
                  maxlength="50"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Cargo"
                  placeholder="Informe o cargo"
                  v-model="beneficiary.job"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  :items="nationalitys"
                  v-model="beneficiary.nationality"
                  label="Nacionalidade"
                  placeholder="Escolha a nacionalidade"
                  item-text="text"
                  item-value="value"
                  outlined
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="beneficiary.stateBirth"
                  :items="states"
                  item-text="label"
                  item-value="label"
                  outlined
                  label="UF de Nascimento"
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Peso(kg)"
                  v-mask="'###'"
                  type="number"
                  min="1"
                  placeholder="Informe o peso"
                  v-model="beneficiary.weight"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="beneficiary.weight = formatter.removeInvalidNumber(beneficiary.weight)"
                  @blur="validateNumbersGreaterThanOne(beneficiary.weight, 'fieldCheckedWeight')"
                  id="fieldCheckedWeight"
                  outlined
                  color="textPrimary"
                  :rules="controlWeight ? ['Favor informar um número válido'] : beneficiary.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(beneficiary.weight)] : []"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Altura(cm)"
                  v-mask="'###'"
                  type="number"
                  min="1"
                  placeholder="Informe a altura"
                  v-model="beneficiary.height"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="beneficiary.height = formatter.removeInvalidNumber(beneficiary.height);"
                  @blur="validateNumbersGreaterThanOne(beneficiary.height, 'fieldCheckedHeight')"
                  id="fieldCheckedHeight"
                  outlined
                  color="textPrimary"
                  :rules="controlHeight ? ['Favor informar um número válido'] : beneficiary.height ? [rule.validatesNumberGreaterThanOrEqualToZero(beneficiary.height)] : []"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="RNE/RNM"
                  v-mask="'#########'"
                  placeholder="Informe o RNE/RNM"
                  v-model="beneficiary.rne"
                  outlined
                  color="textPrimary"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12">
                <label class="title">Dados de Contato</label>
                <v-row class="my-5">
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Telefone Residencial"
                      placeholder="Informe o numero"
                      v-model="beneficiary.telephone"
                      v-mask="'(##) ####-####'"
                      :rules="beneficiary.telephone ? [rule.telephone] : []"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Celular"
                      placeholder="Informe o numero"
                      v-model="beneficiary.cellphone"
                      :rules="beneficiary.cellphone ? [rule.cellphone] : []"
                      v-mask="'(##) #####-####'"
                      outlined
                      lazy-validation
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="E-Mail"
                      placeholder="exemplo@email.com.br"
                      v-model="beneficiary.email"
                      :rules="beneficiary.email ? [rule.email] : []"
                      validate-on-blur
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <label class="title">Identidade</label>
                <v-row class="my-5">
                  <v-col cols="3">
                    <label class="label">Natureza</label>
                    <v-autocomplete
                      class="mt-2"
                      v-model="beneficiary.identityDocumentNature"
                      :items="identityDocumentNatureTypes"
                      @input="beneficiary.identityDocumentNumber = null"
                      placeholder="Informe a natureza do documento"
                      color="textPrimary"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Documento</label>
                    <v-text-field
                      v-model="beneficiary.identityDocumentNumber"
                      v-mask="'NNNNNNNNNNNNNNN'"
                      class="mt-2"
                      placeholder="Informe o número do documento"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Data de Expedição</label>
                    <div class="d-flex flex-column">
                      <v-text-field
                        outlined
                        class="mt-2"
                        v-model="beneficiary.identityDocumentEmissionDate"
                        append-icon="fas fa-calendar-alt"
                        placeholder="DD/MM/YYYY"
                        v-mask="'##/##/####'"
                        color="textPrimary"
                        :rules="beneficiary.identityDocumentEmissionDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                      />
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Orgão Emissor</label>
                    <v-text-field
                      maxlength="8"
                      v-mask="'AAAAAAAA'"
                      @input="beneficiary.identityDocumentIssuer = beneficiary.identityDocumentIssuer.toUpperCase()"
                      v-model="beneficiary.identityDocumentIssuer"
                      class="mt-2"
                      placeholder="Emissor"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                </v-row>
                <v-row class="my-5">
                  <v-col cols="3">
                    <label class="label">UF Emissor</label>
                    <v-autocomplete
                      v-model="beneficiary.identityDocumentState"
                      :items="states"
                      class="mt-2"
                      item-text="label"
                      item-value="label"
                      outlined
                      placeholder="UF emissor"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      clearable
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">País Emissor</label>
                    <v-autocomplete
                      v-model="beneficiary.identityDocumentCountry"
                      :items="country"
                      class="mt-2"
                      item-text="text"
                      item-value="value"
                      outlined
                      placeholder="País emissor"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-if="violations.length > 0">
              <ViolationRulesAlert :violations="violations" />
            </div>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-footer>
      <v-row class="d-flex pt-6 justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2" class="pt-0">
          <v-btn
            x-large
            block
            text
            color="textPrimary"
            @click="onClickBack()"
          >
            <v-icon
              left
              dark>
              fas fa-chevron-left
            </v-icon>
            Voltar
          </v-btn>
        </v-col>
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickCancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          @click="onClickFinish()"
          :loading="isLoadingValidation"
        >
          {{ continueWithCriticism ? 'Prosseguir com crítica' : 'Próximo' }}
          <v-icon
            right
            dark>
            fas fa-chevron-right
          </v-icon>
        </v-btn>
      </v-row>
    </v-footer>

    <div v-if="isCriticizedCarrier">
      <ViewCriticizedViolationDialog />
    </div>

    <SnackbarCustomize
      ref="SnackbarCustomize"
    />
</v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import { VMoney } from 'v-money';
import Rules from '@/shared/validators/formRules';
import GenderService from '@/services-http/contract/GenderService';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import EmploymentRelationshipService from '@/services-http/sdi/EmploymentRelationshipService';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import SimplifiedPrincipalInformations from '@/components/Beneficiary/Holder/SimplifiedPrincipalInformations.vue';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import DocumentService from '@/services-http/sdi/DocumentService';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import MovementRecordFormFieldsToValidate from '@/shared/validators/movementRecordFormFieldsToValidate';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default {
  name: 'BeneficiaryAdditionalInformation',
  directives: { money: VMoney },
  components: { ViolationRulesAlert, SimplifiedPrincipalInformations, ViewCriticizedViolationDialog, SnackbarCustomize },
  data: () => ({
    beneficiary: {
      birthday: null,
      maritalStatus: null,
      gender: null,
      job: null,
      department: null,
      registration: null,
      registrationComplement: null,
      capacityCode: null,
      capacityDescription: null,
      employmentRelationship: null,
      motherName: null,
      stateBirth: null,
      nationality: null,
      pisPasep: null,
      email: null,
      workCard: null,
      cns: null,
      weight: null,
      height: null,
      dnv: null,
      companyName: null,
      companyDocumentNumber: null,
      rne: null,
      cellphone: null,
      telephone: null,
      identityDocumentNature: null,
      identityDocumentNumber: null,
      identityDocumentEmissionDate: null,
      identityDocumentIssuer: null,
      identityDocumentState: null,
      identityDocumentCountry: null,
    },
    beneficiaryContractedPlan: {
      cpf: null,
      name: null,
      admissionDate: null,
      plans: [],
    },
    identityDocumentNatureTypes: [
      'RG',
      'Passaporte',
      'RNE',
    ],
    employmentRelationships: [],
    genders: [],
    isValidForm: true,
    maritalStatus: [],
    movementType: null,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      precision: 2,
      masked: false,
    },
    nationalitys: [
      { value: 'BRASILEIRA', text: 'Brasileira' },
      { value: 'OUTROS', text: 'Outros' },
    ],
    country: [
      { value: 'BRASIL', text: 'Brasil' },
      { value: 'OUTROS', text: 'Outros' },
    ],
    isRHProtegido: false,
    loadedHolder: {},
    controlHeight: false,
    controlWeight: false,
    isCriticizedCarrier: false,
    movementRecord: {},
    financialGroups: [],
    contracts: [],
    subContracts: [],
    plans: [],
    eligibilitiesIds: [],
    addressMovementRecord: {},
    documents: [],
    isLoadingValidation: false,
    hasViolation: false,
    violations: [],
    continueWithCriticism: false,
  }),

  watch: {
    beneficiary: {
      handler(val) {
        if (val.identityDocumentNature === '') {
          this.beneficiary.identityDocumentNature = null;
        }
        if (val.identityDocumentNumber === '') {
          this.beneficiary.identityDocumentNumber = null;
        }
        if (val.identityDocumentEmissionDate === '') {
          this.beneficiary.identityDocumentEmissionDate = null;
        }
        if (val.identityDocumentIssuer === '') {
          this.beneficiary.identityDocumentIssuer = null;
        }
        if (val.identityDocumentCountry === '') {
          this.beneficiary.identityDocumentCountry = null;
        }
        if(this.continueWithCriticism){
          this.continueWithCriticism=false;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mixins: [
    ConstantsMixin,
    VerifyRoutesMixin,
    GenericFunctionsMixin,
    AuthorityManagementMixin,
  ],

  async mounted() {
    this.scrollTo();
    this.loadHolder();
    this.loadDataSessionStorage();
    await this.loadGenders();
    await this.loadMaritalStatus();
    await this.loadEmploymentRelationship();
  },

  methods: {
    async loadDataSessionStorage() {
      if (sessionStorage.getItem('isCriticizedCarrier') && sessionStorage.getItem('movementRecord')) {
        this.violations = sessionStorage.getItem('beneficiaryAdditionalInfoViolations') ? JSON.parse(sessionStorage.getItem('beneficiaryAdditionalInfoViolations')) : [];
        this.isCriticizedCarrier = true;
        this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        this.loadDocumentsByMovementRecordId();
        this.mapBeneficiaryData();
        this.mapBeneficiaryAddressData();
        this.mapBeneficiaryBankData();
      }
      if (sessionStorage.getItem('beneficiaryAdditionalInfo')) {
        this.violations = sessionStorage.getItem('beneficiaryAdditionalInfoViolations') ? JSON.parse(sessionStorage.getItem('beneficiaryAdditionalInfoViolations')) : [];
        this.beneficiary = JSON.parse(sessionStorage.getItem('beneficiaryAdditionalInfo'));
        this.beneficiary.identityDocumentEmissionDate = this.formatter.formatDate(this.beneficiary.identityDocumentEmissionDate);
        this.beneficiary.birthday = this.beneficiary.birthday ? this.formatter.formatDate(this.beneficiary.birthday) : null;
      }
      if (sessionStorage.getItem('beneficiaryContractedPlan')) {
        this.beneficiaryContractedPlan = JSON.parse(sessionStorage.getItem('beneficiaryContractedPlan'));
      }
    },
    onClickBack() {
      const formatDate = this.formatter.formatDateBRtoString(this.beneficiary.birthday);
      const formatDateExpedition = this.formatter.formatDateBRtoString(this.beneficiary.identityDocumentEmissionDate);
      const payload = {
        ...this.beneficiary,
        birthday: formatDate,
        identityDocumentEmissionDate: formatDateExpedition,
        height: this.beneficiary.height ? this.beneficiary.height : null,
        cellphone: this.beneficiary.cellphone ? this.beneficiary.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        telephone: this.beneficiary.telephone ? this.beneficiary.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        dnv: this.beneficiary.dnv ? this.formatter.unmaskDnv(this.beneficiary.dnv) : null,
        capacityCode: this.beneficiaryContractedPlan.capacityCode ? this.beneficiaryContractedPlan.capacityCode : null,
        capacityDescription: this.beneficiaryContractedPlan.capacityDescription ? this.beneficiaryContractedPlan.capacityDescription : null,
        taxAllocationCode: this.beneficiaryContractedPlan.capacityCode ? this.beneficiaryContractedPlan.capacityCode : null,
        taxAllocationDescription: this.beneficiaryContractedPlan.capacityDescription ? this.beneficiaryContractedPlan.capacityDescription : null,
        costCenterDepartment: this.beneficiaryContractedPlan.costCenterDepartment ? this.beneficiaryContractedPlan.costCenterDepartment : null,
        department: this.beneficiaryContractedPlan.costCenterDepartment ? this.beneficiaryContractedPlan.costCenterDepartment : null,
      };

      sessionStorage.setItem('beneficiaryAdditionalInfo', JSON.stringify(payload));
      const query = {
        movementType: this.movementType,
        isRHProtegido: this.isRHProtegido,
        insuranceCarrierId: this.$route.query.insuranceCarrierId,
        beneficiaryType: 'HOLDER',
      };

      this.redirectRouter('BeneficiaryPlanInformation', query);
    },
    onClickCancel() {
      this.clearSessionStorage();
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    onClickFinish() {
      if (!this.continueWithCriticism) {
        this.violations = [];
      }


      if (this.validateFields()) {
        if (!this.isCriticizedCarrier) {
          this.handleNonCriticizedCarrier();
        } else {
          this.handleCriticizedCarrier();
        }

        this.validateAndRedirect();
      }
    },
    handleNonCriticizedCarrier() {
      const formattedPayload = this.formatBeneficiaryPayload();

      sessionStorage.setItem('beneficiaryAdditionalInfo', JSON.stringify(formattedPayload));
    },
    formatBeneficiaryPayload() {
      const formatDate = this.formatter.formatDateBRtoString(this.beneficiary.birthday);
      const formatDateExpedition = this.formatter.formatDateBRtoString(this.beneficiary.identityDocumentEmissionDate);
      let formatCompanyDocumentNumber = this.beneficiary.companyDocumentNumber ? this.beneficiary.companyDocumentNumber : null;
      formatCompanyDocumentNumber = formatCompanyDocumentNumber ? this.formatter.formatCnpj(formatCompanyDocumentNumber) : null;

      return {
        ...this.beneficiary,
        birthday: formatDate,
        identityDocumentEmissionDate: formatDateExpedition,
        companyDocumentNumber: formatCompanyDocumentNumber,
        height: this.beneficiary.height ? this.beneficiary.height : null,
        cellphone: this.beneficiary.cellphone ? this.beneficiary.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        telephone: this.beneficiary.telephone ? this.beneficiary.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        dnv: this.beneficiary.dnv ? this.formatter.unmaskDnv(this.beneficiary.dnv) : null,
        taxAllocationCode: this.beneficiaryContractedPlan.capacityCode ? this.beneficiaryContractedPlan.capacityCode : null,
        taxAllocationDescription: this.beneficiaryContractedPlan.capacityDescription ? this.beneficiaryContractedPlan.capacityDescription : null,
        capacityCode: this.beneficiaryContractedPlan.capacityCode ? this.beneficiaryContractedPlan.capacityCode : null,
        capacityDescription: this.beneficiaryContractedPlan.capacityDescription ? this.beneficiaryContractedPlan.capacityDescription : null,
        costCenterDepartment: this.beneficiaryContractedPlan.costCenterDepartment ? this.beneficiaryContractedPlan.costCenterDepartment : null,
        department: this.beneficiaryContractedPlan.costCenterDepartment ? this.beneficiaryContractedPlan.costCenterDepartment : null,
      };
    },
    handleCriticizedCarrier() {
      this.formatCriticizedBeneficiaryData();
      this.sessionStoreData();

      this.revertDateFormats();
    },
    formatCriticizedBeneficiaryData() {
      this.beneficiary.birthday = this.formatter.formatDateBRtoString(this.beneficiary.birthday);
      this.beneficiary.identityDocumentEmissionDate = this.formatter.formatDateBRtoString(this.beneficiary.identityDocumentEmissionDate);
      this.beneficiary.capacityCode = this.beneficiaryContractedPlan.capacityCode ? this.beneficiaryContractedPlan.capacityCode : null;
      this.beneficiary.capacityDescription = this.beneficiaryContractedPlan.capacityDescription ? this.beneficiaryContractedPlan.capacityDescription : null;
      this.beneficiary.taxAllocationCode = this.beneficiaryContractedPlan.capacityCode ? this.beneficiaryContractedPlan.capacityCode : null;
      this.beneficiary.taxAllocationDescription = this.beneficiaryContractedPlan.capacityDescription ? this.beneficiaryContractedPlan.capacityDescription : null;
      this.beneficiary.costCenterDepartment = this.beneficiaryContractedPlan.costCenterDepartment ? this.beneficiaryContractedPlan.costCenterDepartment : null;
      this.beneficiary.department = this.beneficiaryContractedPlan.costCenterDepartment ? this.beneficiaryContractedPlan.costCenterDepartment : null;
    },
    sessionStoreData() {
      sessionStorage.setItem('beneficiaryAdditionalInfo', JSON.stringify(this.beneficiary));
      sessionStorage.setItem('beneficiaryContractedPlan', JSON.stringify(this.beneficiaryContractedPlan));
      sessionStorage.setItem('beneficiaryAddress', JSON.stringify(this.addressMovementRecord));
      sessionStorage.setItem('beneficiaryBank', JSON.stringify(this.bankMovementRecord));
      sessionStorage.setItem('documents', JSON.stringify(this.documents));
    },
    revertDateFormats() {
      this.beneficiary.birthday = this.formatter.formatDate(this.beneficiary.birthday);
      this.beneficiary.identityDocumentEmissionDate = this.formatter.formatDate(this.beneficiary.identityDocumentEmissionDate);
    },
    async validateAndRedirect() {
      const query = {
        movementType: this.movementType,
        isRHProtegido: this.isRHProtegido,
        insuranceCarrierId: this.$route.query.insuranceCarrierId,
        beneficiaryType: 'HOLDER',
      };

      this.isLoadingValidation = true;

      const insertToValidate = {
        contractedPlan: this.beneficiaryContractedPlan,
        additionalInfo: JSON.parse(sessionStorage.getItem('beneficiaryAdditionalInfo')),
      };
      await this.requestCheckBeneficiaryDuplicity(insertToValidate, query);
    },
    async requestCheckBeneficiaryDuplicity(insertToValidate, query) {
      try {
        await this.movementRecordService.CheckBeneficiaryDuplicity(insertToValidate);

        const fieldsToValidate = this.fieldsToValidate.getFieldsToValidate('beneficiaryAdditionalInformation');
        const movementType = this.movementType ? this.movementType.toLowerCase() : null;

        const functionValidate = movementType === 'insert' ? this.movementRecordService.ValidateInsert(insertToValidate, fieldsToValidate) : this.movementRecordService.ValidateImplantation(insertToValidate, fieldsToValidate);

        if (!this.continueWithCriticism) {
          functionValidate.then(() => {
            this.isLoadingValidation = false;
            this.redirectRouter('AddressBankInformation', query);
          }).catch((error) => {
            this.isLoadingValidation = false;

            if (error.response.status === 422) {
              this.violations = [...error.response.data.violations];

              if (this.hasPermission('sdi_mov_prosseguir_critica')) {
                this.continueWithCriticism = true;
              }

              this.$refs.SnackbarCustomize.open('error', 'Erro de validação de regras no preenchimento do formulário');
            }
          });
        } else {
          sessionStorage.setItem('beneficiaryAdditionalInfoViolations', JSON.stringify(this.violations));
          this.redirectRouter('AddressBankInformation', query);
        }
      } catch (error) {
        this.isLoadingValidation = false;
      }
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
        }
      });
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
        }
      });
    },
    async loadEmploymentRelationship() {
      await this.employmentRelationshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.employmentRelationships = response.data;
        }
      });
    },
    loadHolder() {
      if (sessionStorage.getItem('hasHolder')) {
        this.loadedHolder = JSON.parse(sessionStorage.getItem('hasHolder'));
        if (this.loadedHolder) {
          this.beneficiary = {
            birthday: this.loadedHolder.birthDate ? this.formatter.formatDate(this.loadedHolder.birthDate) : this.loadedHolder.birth_date ? this.formatter.formatDate(this.loadedHolder.birth_date) : null,
            maritalStatus: (this.loadedHolder.maritalStatus && this.loadedHolder.maritalStatus.id) ? this.loadedHolder.maritalStatus.id : this.loadedHolder.maritalStatus ? this.loadedHolder.maritalStatus : null,
            gender: (this.loadedHolder.gender && this.loadedHolder.gender.id) ? this.loadedHolder.gender.id : this.loadedHolder.gender ? this.loadedHolder.gender : null,
            job: this.loadedHolder.jobTitle ? this.loadedHolder.jobTitle : null,
            department: this.loadedHolder.costCenterDepartment ? this.loadedHolder.costCenterDepartment : null,
            registration: this.loadedHolder.registrationPlate ? this.loadedHolder.registrationPlate : null,
            registrationComplement: this.loadedHolder.registrationComplement ? this.loadedHolder.registrationComplement : null,
            capacityCode: this.loadedHolder.costCenterCode ? this.loadedHolder.costCenterCode : null,
            capacityDescription: this.loadedHolder.costCenterName ? this.loadedHolder.costCenterName : null,
            employmentRelationship: null,
            motherName: this.loadedHolder.mothersName ? this.formatter.formatToTitleCase(this.loadedHolder.mothersName) : null,
            stateBirth: this.loadedHolder.stateBirth ? this.loadedHolder.stateBirth.code : null,
            nationality: this.loadedHolder.nationality ? this.loadedHolder.nationality : null,
            pisPasep: this.loadedHolder.pisPasep ? this.loadedHolder.pisPasep : null,
            email: this.loadedHolder.email ? this.loadedHolder.email : null,
            workCard: this.loadedHolder.professionalCardNumber ? this.loadedHolder.professionalCardNumber : null,
            cns: this.loadedHolder.cns ? this.loadedHolder.cns : null,
            dnv: this.loadedHolder.dnv ? this.loadedHolder.dnv : null,
            weight: this.loadedHolder.weight ? this.loadedHolder.weight : null,
            height: this.loadedHolder.height ? this.loadedHolder.height : null,
            rne: this.loadedHolder.rne ? this.loadedHolder.rne : null,
            telephone: (this.loadedHolder.homephoneDDD && this.loadedHolder.homephoneNumber) ? this.loadedHolder.homephoneDDD + this.loadedHolder.homephoneNumber : null,
            cellphone: (this.loadedHolder.cellphoneDDD && this.loadedHolder.cellphoneNumber) ? this.loadedHolder.cellphoneDDD + this.loadedHolder.cellphoneNumber : null,
            companyName: this.loadedHolder.companyName ? this.formatter.removeAccents(this.formatter.formatToTitleCase(this.loadedHolder.companyName)) : null,
            companyDocumentNumber: this.loadedHolder.companyDocumentNumber ? this.loadedHolder.companyDocumentNumber : null,
            identityDocumentNature: this.loadedHolder.natureIdentifyDocument ? this.loadedHolder.natureIdentifyDocument : null,
            identityDocumentNumber: this.loadedHolder.identityDocumentNumber ? this.loadedHolder.identityDocumentNumber : this.loadedHolder.rgDocumentIdentify ? this.loadedHolder.rgDocumentIdentify : null,
            identityDocumentEmissionDate: this.loadedHolder.identityDocumentEmissionDate ? this.formatter.formatDate(this.loadedHolder.identityDocumentEmissionDate) : this.loadedHolder.rgDocumentIssueDate ? this.formatter.formatDate(this.loadedHolder.rgDocumentIssueDate) : null,
            identityDocumentIssuer: this.loadedHolder.identityDocumentIssuer ? this.loadedHolder.identityDocumentIssuer : this.loadedHolder.rgDocumentIssuingBody ? this.loadedHolder.rgDocumentIssuingBody : null,
            identityDocumentState: this.loadedHolder.documentStateIssuer ? this.loadedHolder.documentStateIssuer : null,
            identityDocumentCountry: this.loadedHolder.documentCountryIssue ? this.loadedHolder.documentCountryIssue : null,
          };
        }
      }
    },
    validateFields() {
      return this.$refs.formRegister.validate();
    },
    verifyNegative(value) {
      let formatValue = value;
      formatValue = formatValue.replace(',', '.');
      if (parseFloat(formatValue) < 0) {
        document.getElementById('fieldChecked').focus();
      }
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
    mapBeneficiaryData() {
      if (sessionStorage.getItem('beneficiaryAdditionalInfo') && sessionStorage.getItem('beneficiaryContractedPlan')) {
        this.beneficiary = JSON.parse(sessionStorage.getItem('beneficiaryAdditionalInfo'));
        this.beneficiaryContractedPlan = JSON.parse(sessionStorage.getItem('beneficiaryContractedPlan'));
        this.beneficiary.birthday = this.beneficiary.birthday ? this.formatter.formatDate(this.beneficiary.birthday) : null;
        this.beneficiary.identityDocumentEmissionDate = this.beneficiary.identityDocumentEmissionDate ? this.formatter.formatDate(this.beneficiary.identityDocumentEmissionDate) : null;
      } else {
        this.beneficiaryContractedPlan.cpf = this.movementRecord.holderDocumentNumber;
        this.beneficiaryContractedPlan.name = this.movementRecord.holderName ? this.formatter.formatToTitleCase(this.movementRecord.holderName) : null;
        this.beneficiaryContractedPlan.admissionDate = this.movementRecord.eventDate;
        this.beneficiaryContractedPlan.plans.push({
          financialGroupId: this.movementRecord.financialGroupId ? Number(this.movementRecord.financialGroupId) : null,
          contract: this.movementRecord.contractId ? Number(this.movementRecord.contractId) : null,
          subcontract: this.movementRecord.subcontractId ? Number(this.movementRecord.subcontractId) : null,
          plan: this.movementRecord.plan ? Number(this.movementRecord.plan) : null,
          validityDate: this.movementRecord.startDate ? this.movementRecord.startDate : null,
        });

        this.beneficiary.birthday = this.movementRecord.birthDate ? this.formatter.formatDate(this.movementRecord.birthDate) : null;
        this.beneficiary.maritalStatus = this.movementRecord.maritalStatus;
        this.beneficiary.gender = this.movementRecord.gender;
        this.beneficiary.motherName = this.movementRecord.motherName ? this.formatter.formatToTitleCase(this.movementRecord.motherName) : null;
        this.beneficiary.cns = this.movementRecord.cns;
        this.beneficiary.dnv = this.movementRecord.dnv;
        this.beneficiary.companyName = this.formatter.removeAccents(this.movementRecord.companyName);
        this.beneficiary.companyDocumentNumber = this.movementRecord.companyDocumentNumber;
        this.beneficiary.department = this.movementRecord.costCenterDepartment;
        this.beneficiary.capacityCode = this.movementRecord.taxAllocationCode;
        this.beneficiary.capacityDescription = this.movementRecord.taxAllocationDescription;
        this.beneficiary.employmentRelationship = this.movementRecord.employmentRelationshipId;
        this.beneficiary.workCard = this.movementRecord.professionalCardNumber;
        this.beneficiary.pisPasep = this.movementRecord.pisPasep;
        this.beneficiary.registration = this.movementRecord.registrationPlate;
        this.beneficiary.registrationComplement = this.movementRecord.registrationComplement;
        this.beneficiary.job = this.movementRecord.insuredPosition;
        this.beneficiary.nationality = this.movementRecord.nationality;
        this.beneficiary.stateBirth = this.movementRecord.stateBirth;
        this.beneficiary.weight = this.movementRecord.weight;
        this.beneficiary.height = this.movementRecord.height;
        this.beneficiary.telephone = this.movementRecord.homephoneDDD && this.movementRecord.homephoneNumber ? this.movementRecord.homephoneDDD + this.movementRecord.homephoneNumber : null;
        this.beneficiary.cellphone = this.movementRecord.cellphoneDDD && this.movementRecord.cellphoneNumber ? this.movementRecord.cellphoneDDD + this.movementRecord.cellphoneNumber : null;
        this.beneficiary.email = this.movementRecord.email;
        this.beneficiary.identityDocumentNature = this.movementRecord.natureIdentifyDocument;
        this.beneficiary.identityDocumentNumber = this.movementRecord.rgDocumentIdentify;
        this.beneficiary.identityDocumentEmissionDate = this.movementRecord.rgDocumentIssueDate ? this.formatter.formatDate(this.movementRecord.rgDocumentIssueDate) : null;
        this.beneficiary.identityDocumentIssuer = this.movementRecord.rgDocumentIssuingBody;
        this.beneficiary.identityDocumentState = this.movementRecord.documentStateIssuer;
        this.beneficiary.identityDocumentCountry = this.movementRecord.documentCountryIssue;
      }

    },
    mapBeneficiaryAddressData() {
      if (this.isCriticizedCarrier && !sessionStorage.getItem('beneficiaryAddress')) {
        this.addressMovementRecord = {
          log: this.movementRecord.address,
          code: this.movementRecord.addressZip,
          city: this.movementRecord.addressCity,
          region: this.movementRecord.addressState,
          logType: this.movementRecord.addressType,
          number: this.movementRecord.addressNumber,
          neighborhood: this.movementRecord.addressNeighborhood,
          complement: this.movementRecord.addressComplement,
          country: this.movementRecord.addressCountry,
          ibgeCode: this.movementRecord.ibgeCode,
        };
      } else if (this.isCriticizedCarrier && sessionStorage.getItem('beneficiaryAddress')) {
        this.addressMovementRecord = JSON.parse(sessionStorage.getItem('beneficiaryAddress'));
      }
    },
    mapBeneficiaryBankData() {
      if (this.isCriticizedCarrier && !sessionStorage.getItem('beneficiaryBank')) {
        this.bankMovementRecord = {
          code: this.movementRecord.bank ? Number(this.movementRecord.bank) : null,
          agency: this.movementRecord.branchBank,
          agencyDigit: this.movementRecord.branchBankCheckDigit,
          account: this.movementRecord.bankAccount,
          accountDigit: this.movementRecord.bankAccountCheckDigit,
        };
      } else if (this.isCriticizedCarrier && sessionStorage.getItem('beneficiaryBank')) {
        this.bankMovementRecord = JSON.parse(sessionStorage.getItem('beneficiaryBank'));
      }
    },
    loadDocumentsByMovementRecordId() {
      const queryString = `?movementRecordId=${this.movementRecord.id}`;
      this.documentService.FindDocumentByFilters(queryString).then((response) => {
        if (response && response.data && response.data.length > 0) {
          response.data.forEach((document) => {
            this.documents.push({
              file: {},
              id: document.id,
              name: document.documentType.name,
              titleDocument: document.documentType.name,
              type: 'image/png',
            });
          });
        }
      });
    },
    scrollTo() {
      this.$refs.top.scrollIntoView({ behavior: 'smooth' });
    },
  },
  async created() {
    this.rule = new Rules();
    this.movementRecordService = new MovementRecordService();
    this.fieldsToValidate = new MovementRecordFormFieldsToValidate();
    this.formatter = new Formatters();
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
    this.employmentRelationshipService = new EmploymentRelationshipService();
    this.documentService = new DocumentService();
    this.movementType = this.$route.query.movementType.toLowerCase();
  },
};
</script>
